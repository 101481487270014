import { Notifications } from '@mui/icons-material'
import { Routes, Route } from 'react-router-dom'

import {
  Dashboard,
  ApplicationForm,
  EmailConfirmation,
  Login,
  Register,
  ApplicantHome,
  Messages,
  MyProfile,
  ForgotPassword,
  RegistrationSuccess,
  RolesBalancer,
  PasswordForgotSuccess,
  ResetPassword,
  RequiredDocuments,
  ReferenceForm,
  UnconditionalOfferForm,
  EnrolmentForm,
  ConditionalOfferForm,
  AdminDashboard
} from '../../pages'
import { AgentsList } from '../../pages/Admin/AgentsList'
import { CasExportList } from '../../pages/Admin/CasExportList'
import { ApplicantsList } from '../../pages/Agent/ApplicantsList'
import { AgentDashboard } from '../../pages/Agent/Dashboard'
import { ROLES } from '../../utils/constants'
import { PrivateRoute } from '../PrivateRoute'
import { PublicRoute } from '../PublicRoute'

function RouteList () {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute redirectTo="/login">
            <RolesBalancer />
          </PrivateRoute>
        }
      />
{/* ============================================================ APPLICANT ROUTES ============================================================ */}
      <Route
        path="applicant"
      >
        <Route
          path="application-form"
          element={
            <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
              <ApplicationForm />
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute redirectTo="/">
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <ApplicantHome />
              </PrivateRoute>
            }
          />
          <Route
            path="messages"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <Messages />
              </PrivateRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="required-documents"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <RequiredDocuments />
              </PrivateRoute>
            }
          />
          <Route
            path="conditional-form"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <ConditionalOfferForm />
              </PrivateRoute>
            }
          />
          <Route
            path="unconditional-form"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <UnconditionalOfferForm />
              </PrivateRoute>
            }
          />
          <Route
            path="enrolment-form"
            element={
              <PrivateRoute roles={[ROLES.Applicant]} redirectTo="/">
                <EnrolmentForm />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<h1>404</h1>} />
        </Route>
      </Route>
{/* ============================================================ AGENT ROUTES ============================================================ */}
      <Route
        path="agent"
      >
        <Route
          path="dashboard"
          element={
            <PrivateRoute redirectTo="/">
              <AgentDashboard />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute roles={[ROLES.Agent]} redirectTo="/">
                <ApplicantsList />
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute roles={[ROLES.Agent]} redirectTo="/">
                <MyProfile />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
{/* ============================================================ ADMIN ROUTES ============================================================ */}
      <Route
        path="admin"
      >
        <Route
          path="dashboard"
          element={
            <PrivateRoute redirectTo="/">
              <AdminDashboard />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <PrivateRoute roles={[ROLES.Admin]} redirectTo="/">
                <AgentsList />
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute roles={[ROLES.Admin]} redirectTo="/">
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="cas"
            element={
              <PrivateRoute roles={[ROLES.Admin]} redirectTo="/cas">
                <CasExportList />
              </PrivateRoute>
            }
          />
        </Route>
      </Route>
{/* ============================================================ PUBLIC ROUTES ============================================================ */}
      <Route
        path="/email-confirmation"
        element={
          <PublicRoute>
            <EmailConfirmation />
          </PublicRoute>
        }
      />
      <Route
        path="/registration-success"
        element={
          <PublicRoute>
            <RegistrationSuccess />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />

      <Route
        path="/register"
        element={
          <PublicRoute>
            <Register />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/password-forgot-success"
        element={
          <PublicRoute>
            <PasswordForgotSuccess />
          </PublicRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/reference-form"
        element={
          <PublicRoute>
            <ReferenceForm />
          </PublicRoute>
        }
      />
      <Route path="*" element={<h1>404</h1>} />
    </Routes>
  )
}

export default RouteList
